import React from 'react';

function Footer() {
    return (
        <footer className='footer-container'>
            <p>Created by <a href="https://lorenzosimpson.github.io/portfolio-website/" target="blank"> Lorenzo Simpson</a></p>
        </footer>
    )
}

export default Footer;